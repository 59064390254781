import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { tap, catchError, map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { Credencial } from "../models/credencial.model";
import { environment } from "../../../../../environments/environment";
import { MatDialog } from "@angular/material";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  constructor(public dialog: MatDialog, private httpClient: HttpClient) {}

  public get currentTokenValue(): string {
    return localStorage.getItem("token");
  }

  setCurrentTokenValue(token: string) {
    localStorage.setItem("token", token);
  }

  retornaToken(user: string, password: string) {
    const credencial = new Credencial();

    credencial.login = user;
    credencial.senha = password;

    return this.httpClient
      .post<{ token: string; username: string }>(
        environment.TrustApiHost + "Login/Autenticar",
        credencial
      )
      .pipe(
        tap((res) => {
          localStorage.setItem("token", res.token);
        })
      );
  }

  logout() {
    localStorage.clear();
  }
}
