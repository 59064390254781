import { VigenciaQuestionarioPeso } from './vigenciaQuestionarioPeso.model';
import { Pessoa } from './pessoa.model';
import { PerguntaPerfil } from './perguntaPerfil.model';
import { RespostaPerfil } from './respostaPerfil.model';

export class RespostaQuestionarioEfetivacao {
  idRespostaQuestionarioEfetivacao:number;
vigenciaQuestionarioPeso :VigenciaQuestionarioPeso;
pessoa:Pessoa;
perguntaPerfil:	PerguntaPerfil;
respostaPerfil:	RespostaPerfil;
peso:number;
dataResposta: string;
horaResposta: string;
}
