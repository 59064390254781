import { AtualizarPerfilInvestimentoModule } from './main/content/atualizar-perfil-investimento/atualizar-perfil-investimento.module';
import { SharedModule } from './main/content/shared/shared.module';
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID } from "@angular/core";
import { AppComponent } from "./app.component";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { RouterModule, Routes } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { registerLocaleData, CommonModule } from "@angular/common";
import localePt from "@angular/common/locales/pt";
import { MainModule } from "./main/main.module";
import { ContentModule } from "./main/content/content.module";
import { PerfilInvestimentoModule } from "./main/content/perfil-investimento/perfil-investimento.module";
import { JwtInterceptor } from './main/content/shared/helper/jwt.interceptor';
import { ResultadoModule } from "./main/content/resultado/resultado.module";
import { DialogResultadoModule } from './main/content/resultado/dialog-resultado/dialog-resultado.module';

const appRoutes: Routes = [
  {
    path: "**",
    redirectTo: "atualizarPerfilInvestimento",
  },
];

registerLocaleData(localePt);

@NgModule({
  declarations: [AppComponent],
  imports: [
    RouterModule.forRoot(appRoutes),
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    ContentModule,
    MainModule,
    PerfilInvestimentoModule,
    ResultadoModule,
    DialogResultadoModule,
    SharedModule,
    AtualizarPerfilInvestimentoModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "pt" },
    { provide: LOCALE_ID, useValue: "pt" },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // { provide: ErrorHandler, useClass: GlobalErrorHandler },
    // { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
    // {provide: HTTP_INTERCEPTORS,  useClass: CustomHttpInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
