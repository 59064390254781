import { Injectable } from '@angular/core';

@Injectable()
export class Util {
    constructor() {
    }

    SwapArray(array: any, idx: number, direction: string): any {

        if (idx === 0 && direction === 'up') {
            return array;
        }

        if (idx === array.length - 1 && direction === 'down') {
            return array;
        }

        if (direction === 'up') {
            const beforeItem = array.splice(idx - 1, 1)[0];
            if (!this.IsNullOrUndefined(beforeItem)) {
                array.splice(idx, 0, beforeItem);
            }
        } else {
            const afterItem = array.splice(idx + 1, 1)[0];
            if (!this.IsNullOrUndefined(afterItem)) {
                array.splice(idx, 0, afterItem);
            }
        }

        return array;
    }

    IsNullOrUndefined(value: any) {
        return (value === null || value === 'undefined' || value === undefined);
    }

    NewGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });

    }


}
