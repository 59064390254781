import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResultadoComponent } from './resultado.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule, Routes } from '@angular/router';
const appRoutes: Routes = [
  {
      path: 'resultado',
      component: ResultadoComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes),
    CommonModule,
    SharedModule
  ],
  declarations: [ResultadoComponent]
})
export class ResultadoModule { }
