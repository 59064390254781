import { RespostaPergunta } from './respostaPergunta.model';

export class Pergunta {
  idPergunta: number;
  numeroPergunta: number;
  descricaoPergunta: string;
  respostaPerguntas: RespostaPergunta[];

  
}
