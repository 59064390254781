import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Credencial } from '../shared/models/credencial.model';
import { WebIdentificacaoUsuario } from '../shared/models/webIdentificacaoUsuario.model';
import { AlertaToastrService } from '../shared/services/alertaToastr.service';
import { AuthenticationService } from '../shared/services/authentication.service';
import { WebIdentificacaoUsuarioService } from '../shared/services/webIdentificacaoUsuario.service';
import { WebIdentificacaoUsuarioStore } from '../shared/store/webIdentificacaoUsuario.store';

@Component({
  selector: 'atualizar-perfil-investimento',
  templateUrl: './atualizar-perfil-investimento.component.html',
  styleUrls: ['./atualizar-perfil-investimento.component.scss']
})
export class AtualizarPerfilInvestimentoComponent implements OnInit {
  id: Observable<string>;
  webIdentificacaoUsuario: WebIdentificacaoUsuario = new WebIdentificacaoUsuario();
  credencial: Credencial = new Credencial();
  habilitaSequencia = false;
  mensagem: string;
  cliente: string = environment.cliente;

  constructor(private readonly router: Router,
              private route: ActivatedRoute,
              private authenticationService: AuthenticationService,
              private webIdentificacaoUsuarioService: WebIdentificacaoUsuarioService,
              private webIdentificacaoUsuarioStore: WebIdentificacaoUsuarioStore,
              private toastrService: AlertaToastrService,) {
    this.id = this.route.queryParamMap.pipe(
      map((params) => params.get("id") || "")
    );

    this.credencial.login = "TRUSTWEB";
    this.credencial.senha = "TVARLBUTIB";
    this.authenticationService
      .retornaToken(this.credencial.login, this.credencial.senha)
      .subscribe(
        login => {
          if(login) {
          this.id.subscribe((id) => {
            if(id){
            this.webIdentificacaoUsuarioService
              .validaAcesso(id)
              .subscribe((webIdentificacaoUsuario) => {
                if (webIdentificacaoUsuario) {
                  this.webIdentificacaoUsuario = webIdentificacaoUsuario;
                  this.webIdentificacaoUsuarioStore.update(webIdentificacaoUsuario);
                  this.habilitaSequencia = true;

                  window.history.pushState({}, document.title, window.location.pathname);
                }
              });
            }
          });
        }
        },
        error => {
          this.mensagem = 'Erro ao carregar as informações de autenticação: ' + error;
          this.toastrService.showToasterError(this.mensagem, 'Erro!');
          console.error(error);
        }
      );
  }

  ngOnInit() {
    console.log(this.cliente)
  }

  chamaTelaResultado() {
    this.router.navigate(['perfilInvestimento']);
  }
}
