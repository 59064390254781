import { Injectable } from "@angular/core";
import { ApiService } from "./api.services";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Util } from "../util";
import { Router } from "@angular/router";
import { BaseService } from "../../../../core/base.service";
import { ViewModelPergunta } from "../models/viewModelPergunta.model";
import { Pergunta } from "../models/pergunta.model";
import { SugestaoPerfil } from "../models/sugestaoPerfil.model";
import { PerfilSelecaoParticipante } from "../models/perfilSelecaoParticipante.model";

@Injectable()
export class PerguntaPerfilService
  extends ApiService
  implements BaseService<Pergunta> {
  private strAction = "PerguntaPerfil";

  constructor(
    httpClient: HttpClient,
    private http: HttpClient,
    private util: Util,
    private route: Router
  ) {
    super(httpClient, route);
  }

  perguntaPerfil(viewModelPergunta: ViewModelPergunta) {
    return this.post<Pergunta>(
      this.strAction + `/BuscaPerguntaPerfil`,
      viewModelPergunta
    );
  }

  quantidadePerguntaPerfil(dataReferencia: string): Observable<number> {
    return this.get<number>(
      this.strAction + `/QuantidadePerguntaPerfil/${dataReferencia}`
    );
  }

  resultadoPerguntaPerfil(viewModelPergunta: ViewModelPergunta) {
    return this.post<SugestaoPerfil>(this.strAction + '/ResultadoPerguntaPerfil', viewModelPergunta);
  }

  perfilSelecaoParticipante(idPlano: number) {
    return this.get<PerfilSelecaoParticipante[]>(this.strAction + `/PerfilSelecaoParticipante/${idPlano}`);
  }

  getAll(): Observable<Pergunta[]> {
    throw new Error("Method not implemented.");
  }
  deleteById(id: number) {
    throw new Error("Method not implemented.");
  }
  save(entity: Pergunta): Observable<Pergunta> {
    throw new Error("Method not implemented.");
  }
}
