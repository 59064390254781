import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { PerguntaPerfilService } from "../shared/services/perguntaPerfil.service";
import { ViewModelPergunta } from "../shared/models/viewModelPergunta.model";
import { Pergunta } from "../shared/models/pergunta.model";
import { Router } from "@angular/router";
import { RespostaQuestionarioEfetivacaoService } from "../shared/services/questionarioPergunta.service";
import { ViewModelRespostaTemporaria } from "../shared/models/viewModelRespostaTemporaria.model";
import { RespostaQuestionarioEfetivacao } from "../shared/models/respostaQuestionarioEfetivacao.model";
import { WebIdentificacaoUsuarioStore } from "../shared/store/webIdentificacaoUsuario.store";
import { AlertaToastrService } from "../shared/services/alertaToastr.service";

/** @title Basic drawer */
@Component({
  selector: "perfil-investimento",
  templateUrl: "perfil-investimento.component.html",
  styleUrls: ["perfil-investimento.component.scss"],
})
export class PerfilInvestimentoComponent implements OnInit {
  isLinear = true;
  formGroup: FormGroup;
  pergunta: Pergunta = new Pergunta();
  questionarioRespostaEfetivacao: RespostaQuestionarioEfetivacao = new RespostaQuestionarioEfetivacao();
  form: FormArray;
  viewModelPergunta: ViewModelPergunta = new ViewModelPergunta();
  viewModelRespostaTemporaria: ViewModelRespostaTemporaria = new ViewModelRespostaTemporaria();
  dataReferencia = new Date();
  quantidadePergunta: number;
  idPessoa: number;
  idPlano: number;
  index: number;
  mensagem: string;
  loading = false;
  constructor(
    private _formBuilder: FormBuilder,
    private perguntaPerfilService: PerguntaPerfilService,
    private readonly router: Router,
    private questionarioPerguntaService: RespostaQuestionarioEfetivacaoService,
    private webIdentificacaoUsuarioStore: WebIdentificacaoUsuarioStore,
    private toastrService: AlertaToastrService
  ) {
    this.retornaQuantidadePerguntas(this.dataReferencia);
    this.consultaPergunta();
    webIdentificacaoUsuarioStore
      .getCurrent()
      .subscribe((webIdentificacaoUsuario) => {
        if (webIdentificacaoUsuario) {
          this.idPessoa = webIdentificacaoUsuario.idPessoa;
          this.idPlano = webIdentificacaoUsuario.idPlano;
        }
      });
  }
  ngOnInit() {
    this.formGroup = this._formBuilder.group({
      form: this._formBuilder.array([this.init()]),
    });

    this.index = 1;
  }
  formataData(dataReferencia) {
    const dateAux = new Date(dataReferencia);
    console.log(dateAux);
    return (
      dateAux.getFullYear() +
      "-" +
      (dateAux.getMonth() > 8
        ? (+dateAux.getMonth() + 1).toString()
        : "0" + (+dateAux.getMonth() + 1).toString()) +
      "-" +
      (dateAux.getDate() > 9 ? dateAux.getDate() : "0" + dateAux.getDate())
    );
  }

  init() {
    return this._formBuilder.group({
      pergunta: [""],
      resposta: ["", Validators.required],
    });
  }
  retornaQuantidadePerguntas(dateAux) {
    let novaData = this.formataData(dateAux);
    console.log("nova data", novaData);
    this.perguntaPerfilService
      .quantidadePerguntaPerfil(novaData)
      .subscribe((quantidade) => {
        this.quantidadePergunta = quantidade;
        console.log("quantidade", this.quantidadePergunta);
        for (let i = 1; i < this.quantidadePergunta; i++) {
          this.addItem();
        }
      });
  }
  chamaTelaResultado(i: number) {
    this.gravaDados(i);
    this.router.navigate(["resultado"]);
  }
  consultaPergunta() {
    this.viewModelPergunta.numeroPergunta = 1;
    if (this.viewModelPergunta) {
      this.perguntaPerfilService
        .perguntaPerfil(this.viewModelPergunta)
        .subscribe((perguntas) => {
          this.pergunta = perguntas;
          console.log("perguntas", perguntas);
        });
    }
  }

  addItem() {
    this.form = this.formGroup.get("form") as FormArray;
    this.form.push(this.init());
  }

  gravaDados(i: number) {
    this.viewModelRespostaTemporaria.idPessoa = this.idPessoa;
    this.viewModelRespostaTemporaria.idPlano = this.idPlano;
    this.viewModelRespostaTemporaria.numeroPergunta = this.pergunta.numeroPergunta;
    console.log(this.viewModelRespostaTemporaria);
    this.viewModelRespostaTemporaria.dataResposta = new Date();
    this.questionarioPerguntaService
      .gravaResposta(this.viewModelRespostaTemporaria)
      .subscribe(
        (resposta) => {
          this.questionarioRespostaEfetivacao = resposta;
          this.viewModelPergunta.numeroPergunta = i + 1;

          if (this.viewModelPergunta.numeroPergunta <= this.quantidadePergunta) {
            this.perguntaPerfilService
              .perguntaPerfil(this.viewModelPergunta)
              .subscribe((perguntas) => {
                this.pergunta = perguntas;
                this.questionarioRespostaEfetivacao = new RespostaQuestionarioEfetivacao();
                this.loading = false;
                this.viewModelRespostaTemporaria = new ViewModelRespostaTemporaria();
              });
          }
        },

        (erro) => {
          this.mensagem = "Erro ao salvar a resposta!";
          this.toastrService.showToasterError(this.mensagem, "Erro!");
        }
      );
  }
  apagada(i: number) {
    this.loading = true;
    this.gravaDados(i);
    this.pergunta = new Pergunta();
  }
  voltaPergunta(i: number) {
    this.pergunta = new Pergunta();
    this.viewModelPergunta.numeroPergunta = i + 1;

    if (this.viewModelPergunta) {
      this.perguntaPerfilService
        .perguntaPerfil(this.viewModelPergunta)
        .subscribe((perguntas) => {
          this.pergunta = perguntas;
          this.questionarioRespostaEfetivacao = new RespostaQuestionarioEfetivacao();
        });
    }
  }
}
