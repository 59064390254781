import { ViewModelWebIdentificacaoUsuario } from './../models/viewModelWebIdentificacaoUsuario.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/core/base.service';
import { WebIdentificacaoUsuario } from '../models/webIdentificacaoUsuario.model';
import { Util } from '../util';
import { ApiService } from './api.services';

@Injectable({
  providedIn: 'root'
})
export class WebIdentificacaoUsuarioService extends ApiService implements BaseService<WebIdentificacaoUsuario> {
  private strAction = "WebIdentificacaoUsuario";

constructor(
    httpClient: HttpClient,
    private http: HttpClient,
    private util: Util,
    private route: Router
  ) {
    super(httpClient, route);
  }
  getAll(): Observable<WebIdentificacaoUsuario[]> {
    throw new Error('Method not implemented.');
  }
  deleteById(id: number) {
    throw new Error('Method not implemented.');
  }
  save(entity: WebIdentificacaoUsuario): Observable<WebIdentificacaoUsuario> {
    throw new Error('Method not implemented.');
  }

  geraAcesso(viewModelWebIdentificacaoUsuario: ViewModelWebIdentificacaoUsuario) {
    return this.post<string>(this.strAction + '/GeraAcesso', viewModelWebIdentificacaoUsuario);
  }

  validaAcesso(token: string): Observable<WebIdentificacaoUsuario> {
    return this.get<WebIdentificacaoUsuario>(this.strAction + `/ValidaAcesso/${token}`);
  }

}
