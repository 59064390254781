import { SharedModule } from '../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { PerfilInvestimentoComponent } from './perfil-investimento.component';

const appRoutes: Routes = [
  {
      path: 'perfilInvestimento',
      component: PerfilInvestimentoComponent
  }
];

@NgModule({
  declarations: [PerfilInvestimentoComponent],
  imports: [
    RouterModule.forRoot(appRoutes),
    CommonModule,
    SharedModule,
  ],
  exports: [PerfilInvestimentoComponent]
})
export class PerfilInvestimentoModule { }
