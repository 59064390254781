import { SharedModule } from './../shared/shared.module';
import { AtualizarPerfilInvestimentoComponent } from './atualizar-perfil-investimento.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

const appRoutes: Routes = [
  {
      path: 'atualizarPerfilInvestimento',
      component: AtualizarPerfilInvestimentoComponent
  }
];

@NgModule({
  declarations: [AtualizarPerfilInvestimentoComponent],
  imports: [
    RouterModule.forRoot(appRoutes),
    CommonModule,
    SharedModule
  ],
  exports: [
    AtualizarPerfilInvestimentoComponent
  ]
})
export class AtualizarPerfilInvestimentoModule { }
