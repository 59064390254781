import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Output() opcaoMenu = new EventEmitter<string>();
  permissao = true;
  constructor(
  ) {
   
  

    

  }

  ngOnInit(): void {
  }
  onChange(event): void {
    this.opcaoMenu.emit(event);
    // this.resetForm(this.formularioOpcoes);
  }
}
