import { EscolhaPessoaService } from "./services/escolhaPessoa.service";
import { WebIdentificacaoUsuarioService } from "./services/webIdentificacaoUsuario.service";
import { StylePaginatorDirective } from "./directives/style-paginator.directive";
import { SpinnerService } from "./services/spinner.service";
import { PaginaAnteriorService } from "./services/paginaAnterior.service";
import { MatRippleModule, MatNativeDateModule } from "@angular/material/core";
import { MatGridListModule } from "@angular/material/grid-list";
import { NgModule } from "@angular/core";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTabsModule } from "@angular/material/tabs";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Util } from "./util";
import { VersaoService } from "./services/versao.service";
import { VersaoAppStore } from "./store/versaoApp.store";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { DateFormatPipe } from "./pipes/date-format.pipe";
import { ToastrModule } from "ngx-toastr";
import { MenuModule } from "../components/menu/menu.module";
import { DatePipe } from "@angular/common";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { NgxMaskModule } from "ngx-mask";
import { DxReportViewerModule } from "devexpress-reporting-angular";
import { MatStepperModule } from "@angular/material";
import { PerguntaPerfilService } from "./services/perguntaPerfil.service";
import { WebIdentificacaoUsuarioStore } from "./store/webIdentificacaoUsuario.store";
import { RespostaQuestionarioEfetivacaoService } from "./services/questionarioPergunta.service";
import { NgxLoadingModule } from "ngx-loading";
@NgModule({
  declarations: [DateFormatPipe, StylePaginatorDirective],
  imports: [
    ReactiveFormsModule,
    MatAutocompleteModule,
    NgxLoadingModule.forRoot({}),
    MatFormFieldModule,
    MatDialogModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatInputModule,
    MatSelectModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatTabsModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatToolbarModule,
    MatSidenavModule,
    FormsModule,
    MatListModule,
    MatGridListModule,
    MatRippleModule,
    MatTableModule,
    MatPaginatorModule,
    MatNativeDateModule,
    ToastrModule.forRoot(),
    MenuModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatStepperModule,
    NgxMaskModule.forRoot(),
    DxReportViewerModule,
  ],
  exports: [
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatDialogModule,
    MatStepperModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatInputModule,
    MatSelectModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatTabsModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule,
    MatCardModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatGridListModule,
    MatRippleModule,
    MatTableModule,
    MatPaginatorModule,
    MatNativeDateModule,
    DateFormatPipe,
    MenuModule,
    MatTooltipModule,
    MatProgressBarModule,
    StylePaginatorDirective,
    NgxMaskModule,
    DxReportViewerModule,
    NgxLoadingModule,
  ],
  providers: [
    Util,
    VersaoService,
    VersaoAppStore,
    PaginaAnteriorService,
    DatePipe,
    SpinnerService,
    PerguntaPerfilService,
    WebIdentificacaoUsuarioService,
    WebIdentificacaoUsuarioStore,
    EscolhaPessoaService,
    RespostaQuestionarioEfetivacaoService,
  ],
})
export class SharedModule {}
