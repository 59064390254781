import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogResultadoComponent } from './dialog-resultado.component';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule, Routes } from '@angular/router';
const appRoutes: Routes = [
  {
      path: 'Modal',
      component: DialogResultadoComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes),
    SharedModule
  ],
  declarations: [DialogResultadoComponent]
})
export class DialogResultadoModule { }
