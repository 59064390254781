import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaginaAnteriorService {
  private statusSource = new BehaviorSubject<boolean>(undefined);
  statusAtual = this.statusSource.asObservable();

constructor() { }

changeStatus(voltar: boolean) {
  this.statusSource.next(voltar);
}

}
