import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { WebIdentificacaoUsuario } from "../models/webIdentificacaoUsuario.model";

@Injectable()
export class WebIdentificacaoUsuarioStore {
  private Subject = new BehaviorSubject<WebIdentificacaoUsuario>(null);
  webIdentificacaoUsuario = this.Subject.asObservable();

  constructor() {}

  getCurrent(): Observable<WebIdentificacaoUsuario> {
    return this.webIdentificacaoUsuario;
  }

  update(webIdentificacaoUsuario: WebIdentificacaoUsuario) {
    this.Subject.next(webIdentificacaoUsuario);
  }
}
