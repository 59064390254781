import { ViewModelWebIdentificacaoUsuario } from "../models/viewModelWebIdentificacaoUsuario.model";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { BaseService } from "src/app/core/base.service";
import { WebIdentificacaoUsuario } from "../models/webIdentificacaoUsuario.model";
import { Util } from "../util";
import { ApiService } from "./api.services";
import { ViewModelRespostaTemporaria } from '../models/viewModelRespostaTemporaria.model';
import { RespostaQuestionarioEfetivacao } from '../models/respostaQuestionarioEfetivacao.model';

@Injectable({
  providedIn: "root",
})
export class RespostaQuestionarioEfetivacaoService
  extends ApiService
  implements BaseService<RespostaQuestionarioEfetivacao> {
    private strAction = "RespostaQuestionarioEfetivacao";
    constructor(
      httpClient: HttpClient,
      private http: HttpClient,
      private util: Util,
      private route: Router
    ) {
      super(httpClient, route);
    }
  getAll(): Observable<any[]> {
    throw new Error("Method not implemented.");
  }
  deleteById(id: number) {
    throw new Error("Method not implemented.");
  }
  save(entity: any): Observable<any> {
    throw new Error("Method not implemented.");
  }
 
  gravaResposta(viewModelRespostaTemporaria: ViewModelRespostaTemporaria) {
    return this.post<RespostaQuestionarioEfetivacao>(
      this.strAction + "/PostResposta",
      viewModelRespostaTemporaria
    );
  }
}
