import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { BaseService } from 'src/app/core/base.service';
import { ViewModelEscolhaPessoa } from '../models/ViewModelEscolhaPessoa.model';
import { Util } from '../util';
import { AlertaToastrService } from './alertaToastr.service';
import { ApiService } from './api.services';

@Injectable({
  providedIn: 'root'
})
export class EscolhaPessoaService extends ApiService implements BaseService<ViewModelEscolhaPessoa> {
  private strAction = "EscolhaPessoa";
  mensagem: string;

constructor(
    httpClient: HttpClient,
    private http: HttpClient,
    private util: Util,
    private route: Router,
    private toastrService: AlertaToastrService,
  ) {
    super(httpClient, route);
  }
  getAll(): Observable<ViewModelEscolhaPessoa[]> {
    throw new Error('Method not implemented.');
  }
  deleteById(id: number) {
    throw new Error('Method not implemented.');
  }
  save(entity: ViewModelEscolhaPessoa): Observable<ViewModelEscolhaPessoa> {
    throw new Error('Method not implemented.');
  }

  postEscolhaPessoa(viewModelEscolhaPessoa: ViewModelEscolhaPessoa) {
    return this.post<any>(this.strAction + '/PostEscolhaPessoa', viewModelEscolhaPessoa).pipe(
      retry(1),
      catchError(this.handleError)
    );;
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // this.mensagem = 'Erro ao salvar o perfil: ' + errorMessage;
    // this.toastrService.showToasterError(this.mensagem, 'Erro!');
    return throwError(errorMessage);
  }

}
